<template>
  <div class="w-full relative p-4 overflow-y-auto">
    <span class="font-semibold text-2xl text-gray-700">Vorlagen</span>
    <div class="pt-5">
      <Transition>
        <JobDetails
          v-if="editedJob"
          :newJob="false"
          :applications="{ received: [], interview: [], offer: [] }"
          :job="editedJob"
          :user="user"
          :cryptKey="encryptionKey"
          @jobSaved="jobSaved"
          @closeJobDetails="editedJob = false"
        />
      </Transition>
      <div class="w-full h-full p-1 flex flex-col sm:flex-row">
        <div>
          <span class="font-bold text-lg">E-Mails</span>
          <div class="pl-3">
            <span
              @click="setMode('Received')"
              v-bind:class="[
                mode == 'Received' ? 'text-primary underline' : '',
              ]"
              class="mt-3 cursor-pointer block bold text-xl"
              >Eingangsbestätigung</span
            >
            <span
              @click="setMode('Interview')"
              v-bind:class="[
                mode == 'Interview' ? 'text-primary underline' : '',
              ]"
              class="mt-3 cursor-pointer bold block text-xl"
              >Intervieweinladung</span
            >
            <span
              @click="setMode('Cancel')"
              v-bind:class="[mode == 'Cancel' ? 'text-primary underline' : '']"
              class="mt-3 mr-10 cursor-pointer inline-block bold text-xl"
              >Absage</span
            >
          </div>
          <span
            class="font-bold text-lg block mt-5 cursor-pointer"
            @click="setMode('jobs')"
            v-bind:class="[mode == 'jobs' ? 'text-primary underline' : '']"
            >Anzeigen</span
          >

          <span class="font-bold text-lg mt-5 inline-block">Sonstiges</span>
          <div class="pl-3">
            <span
              @click="setMode('aboutus')"
              v-bind:class="[mode == 'aboutus' ? 'text-primary underline' : '']"
              class="mt-3 cursor-pointer block bold text-xl"
              >Über Uns</span
            >
            <span
              @click="setMode('benefits')"
              v-bind:class="[
                mode == 'benefits' ? 'text-primary underline' : '',
              ]"
              class="mt-3 cursor-pointer block bold text-xl"
              >Benefits</span
            >
          </div>
        </div>
        <div class="text-left grow">
          <div
            class="inline-block align-top mt-4 sm:mt-0 px-2 sm:px-10 text-left w-full h-full"
          >
            <span class="text-2xl font-bold">{{ title }}</span>
            <div class="mt-5 w-full h-full">
              <div
                v-if="mode != 'benefits' && mode != 'aboutus' && mode != 'jobs'"
              >
                <span>Betreff</span><br />
                <input
                  class="w-full bg-gray-100 p-3 mt-2"
                  placeholder="Betreff"
                  v-model="templates['subject' + mode]"
                />
                <span class="mt-2"
                  >Mögliche Platzhalter:
                  <span
                    class="cursor-pointer"
                    @click="templates[mode] += '{Name}'"
                    >{Name}</span
                  >
                  <span
                    class="cursor-pointer"
                    @click="templates[mode] += '{Jobtitel}'"
                    >{Jobtitel}</span
                  >
                  <span
                    class="cursor-pointer"
                    @click="templates[mode] += '{Datum}'"
                    >{Datum}</span
                  ></span
                ><span
                  v-if="mode == 'Interview'"
                  class="cursor-pointer"
                  @click="templates[mode] += '{Interviewdatum}'"
                  >{Interviewdatum}</span
                ><span
                  v-if="mode == 'Interview'"
                  class="cursor-pointer"
                  @click="templates[mode] += '{Interviewuhrzeit}'"
                  >{Interviewuhrzeit}</span
                >
              </div>
              <div v-if="mode == 'jobs'">
                <div class="">
                  <JobTileV2Vue
                    v-for="job in jobs"
                    :key="job.id"
                    :job="job"
                    :currentJob="editedJob"
                    @click="editJob(job)"
                    @openJobDetails="editJob(job)"
                  />
                  <span v-if="jobs.length == 0" class="font-bold text-lg">Es gibt aktuell keine archivierten Stellen</span>
                </div>
              </div>
              <textarea
                v-if="mode == 'Received'"
                class="mt-3 p-5 bg-white w-full h-96"
                v-model="templates.templateReceived"
              ></textarea>
              <textarea
                v-if="mode == 'Cancel'"
                class="mt-3 p-5 bg-white w-full h-96"
                v-model="templates.templateCancel"
              ></textarea>
              <textarea
                v-if="mode == 'Interview'"
                class="mt-3 p-5 bg-white w-full h-96"
                v-model="templates.templateInterview"
              ></textarea>
              <textarea
                v-if="mode == 'benefits'"
                class="mt-3 p-5 bg-white w-full h-96"
                v-model="templates.benefits"
              ></textarea>
              <textarea
                v-if="mode == 'aboutus'"
                class="mt-3 p-5 bg-white w-full h-96"
                v-model="templates.aboutus"
              ></textarea>
              <div v-if="mode != 'jobs'">
              <button
                v-if="!saved"
                @click="saveTemplate()"
                class="float-right text-primary border-primary mt-3"
              >
                Speichern
              </button>
              <button
                v-else
                @click="saveTemplate()"
                class="float-right text-green-500 border-green-500 mt-3"
              >
                Speichern
              </button>
              <button @click="resetTemplate()" class="float-right mt-3 mr-3">
                Zurücksetzen
              </button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { useCookies } from "vue3-cookies";
import API from "@/API/Business";
import JobTileV2Vue from '@/components/tiles/JobTileV2.vue';
import JobDetails from '@/components/JobDetails.vue';
export default {
 setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  name: "TemplateView",
  props: {
    user: Object,
  },
  components: {
    JobTileV2Vue,
    JobDetails
  },
  data() {
    return {
      mode: "Received",
      title: "Eingangsbestätigung",
      templates: {},
      saved: false,
      jobs: [],
      editedJob: null,
      encryptionKey: null
    };
  },
  methods: {
    jobSaved(){
      this.editedJob = false;
      this.getArchivedJobs();
    },
    editJob(job) {
      this.editedJob = job;
    },
    setMode(mode) {
      this.saved = false;
      this.mode = mode;
      switch (mode) {
        case "Received":
          this.title = "Eingangsbestätigung";
          break;
        case "Interview":
          this.title = "Intervieweinladung";
          break;
        case "Cancel":
          this.title = "Absage";
          break;
        case "aboutus":
          this.title = "Über " + this.user.currentTenantData.name;
          break;
        case "benefits":
          this.title = "Unternehmensbenefits";
          break;
        case "jobs":
          this.title = "Archivierte Anzeigen";
          this.getArchivedJobs();
          break;
      }
    },
    saveTemplate() {
      this.saved = true;
      API.editTemplates(this.templates, () => {});
    },
    selectTenant(company) {
      this.selected = company;
    },
    resetTemplate() {
      this.templates[this.mode] = "";
    },
    init() {
      API.getTemplates((templates) => {
        console.log(templates);
        this.templates = templates;
      });
      if (this.user.currentTenant) {
        this.encryptionKey = localStorage.getItem(
          "encryptionKey" + this.user.currentTenant
        );
      }
    },
    getArchivedJobs() {
      API.getArchivedJobs((jobs) => {
        this.jobs = jobs
      });
    },
  },

  watch: {
    currentTenant() {
      console.log("change");
      this.init();
    },
  },
  computed: {
    currentTenant() {
      return this.user.currentTenant;
    },
  },
  created: function () {
    if (this.user.currentTenant) this.init();
  },
};
</script>
<style scoped>
input {
  border-bottom: 1px solid black;
}
</style>