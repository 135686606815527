<template>
  <add-file-modal
    v-if="showAddFileModal"
    kind="Dokument"
    :files="files"
    @closeModal="showAddFileModal = false"
    @addFile="addFile"
  />
  <div
    class="absolute pt-20 sm:pt-0 bottom-0 sm:relative h-full w-full md:w-1/3 min-w-side"
  >
    <div
      class="p-5 relative overflow-auto h-full w-full border-l-2 border-secondary bg-white"
    >
      <div @click="$emit('closeDetails')" class="iconborder">
        <font-awesome-icon icon="xmark" class="inborder" />
      </div>
      <div class="text-center w-full overflow-auto">
        <div class="">
          <input
            class="text-center text-2xl font-bold w-full"
            placeholder="ApplyHR GmbH"
            v-model="editableApplication.companyName"
            @change="updateApplication('companyName')"
          />
          <br />
          <input
            class="text-center text-lg font-semibold w-full"
            placeholder="Jobtitel hinzufügen"
            v-model="editableApplication.jobtitle"
            @change="updateApplication('jobtitle')"
          />
          <span>Angelegt: {{ editableApplication.created_at }}</span
          ><br />
        </div>
        <div class="w-full text-left mt-6">
          <span class="text-lg">Status</span>
          <div
            class="rounded-full float-right bg-secondary text-primary font-semibold py-1 px-4"
          >
            <select
              class="bg-secondary font-bold"
              v-model="editableApplication.status"
              @change="updateApplication('status')"
            >
              <option value="Abgesagt">Abgesagt</option>
              <option value="Verschickt">Verschickt</option>
              <option value="Interview">Interview</option>
              <option value="Angebot">Angebot</option>
            </select>
          </div>
        </div>
        <div
          v-if="editableApplication.status == 'Interview'"
          class="text-center relative"
        >
          <div class="w-full text-left py-1 flex flex-row">
            <span class="text">Interviewtermin: </span>
            <div class="lign-middle text-right grow">
              <input
                id="test"
                class="w-48 inline-block font-semibold text-left"
                type="datetime-local"
                v-model="editableApplication.scheduled"
                @change="updateApplication('scheduled')"
              />
            </div>
          </div>
        </div>
        <div class="px-4">
          <div class="border-b border-b-black w-full mt-4 my-2"></div>
        </div>
        <div class="w-full text-left">
          <span class="text-lg font-bold">Ansprechpartner</span>
          <div class="w-full text-left py-1 flex flex-row">
            <span class="text">Name</span>
            <div class="align-middle text-right grow">
              <input
                class="w-36 inline-block font-semibold text-left"
                placeholder="Name"
                v-model="editableApplication.contact_name"
                @change="updateApplication('contact_name')"
              />
            </div>
          </div>
          <div class="w-full text-left py-1 flex flex-row">
            <span class="text">Telefonnummer</span>
            <div class="align-middle text-right grow">
              <input
                class="w-36 inline-block font-semibold text-left"
                placeholder="Telefonnummer"
                v-model="editableApplication.contact_phone"
                @change="updateApplication('contact_phone')"
              />
            </div>
          </div>
          <div class="w-full text-left py-1 flex flex-row">
            <span class="text">E-Mail</span>
            <div class="align-middle text-right grow">
              <input
                class="w-36 inline-block font-semibold text-left"
                placeholder="E-Mail"
                v-model="editableApplication.contact_mail"
                @change="updateApplication('contact_mail')"
              />
            </div>
          </div>

          <div class="px-4">
            <div class="border-b border-b-black w-full mt-2 my-2"></div>
          </div>
          <span class="text-lg font-bold">Sonstiges</span>

          <div class="w-full text-left py-1 flex flex-row">
            <span class="text">Website</span>
            <div class="align-middle text-right grow">
              <input
                class="w-36 inline-block font-semibold text-left"
                placeholder="Website"
                v-model="editableApplication.website"
                @change="updateApplication('website')"
              />
            </div>
          </div>
          <div class="w-full text-left py-1 flex flex-row">
            <span class="text">Stellenbeschreibung</span>
            <div class="align-middle text-right grow">
              <input
                class="w-36 inline-block font-semibold text-left"
                placeholder="Stellenbeschreibung"
                v-model="editableApplication.weblink"
                @change="updateApplication('weblink')"
              />
            </div>
          </div>
        </div>
        <div class="inline-block w-full mt-3 text-center">
          <button class="bad mx-2" @click="$emit('deleteApplication',application.id)">Löschen</button>
        </div>
        <div class="mt-7 text-left">
          <span class="text-xl">Dokumente</span>
          <div class="iconborder" @click="showAddFileModal = true">
            <font-awesome-icon icon="plus" class="inborder" />
          </div><br>
          <span class="text-sm text-red-500 font-bold">Diese Dokumente sind für den Arbeitgeber sichtbar.</span>
          <span v-if="application.files.length == 0" class="mt-5 block"
            > - </span
          >
          <div v-else>
            <div
              v-for="file in editableApplication.files"
              :key="file.id"
              class="mt-2 relative"
            >
              <div class="w-full overflow-hidden">
                <span
                  class="border-b-2 border-secondary cursor-pointer"
                  @click="getFile(file)"
                  >{{ file.originalName }}</span
                >
                <div class="float-right">
                  <span class="text-secondary">{{ file.documentType }}</span>

                  <font-awesome-icon
                    icon="trash"
                    @click="deleteFile(file)"
                    class="pr-1 px-2 text-secondary cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-7">
          <div class="w-full text-left">
            <span class="text-xl">Kommentare</span>
            <div class="iconborder" @click="showAddComment()">
              <font-awesome-icon icon="plus" class="inborder" />
            </div><br>
            <span class="text-sm text-red-500 font-bold">Kommentare sind nur für dich sichtbar.</span>
          </div>
          <div v-show="addCommentVisible" class="relative p-0 pt-3">
            <textarea
              ref="comment"
              @keyup.enter="addComment()"
              v-model="newComment"
              class="border p-3 w-full h-20 bg-gray-100 resize-none"
            ></textarea>
            <div class="w-full text-right">
              <button class="py-0 px-1 rounded-2xl" @click="addComment()">
                Hinzufügen
              </button>
            </div>
          </div>
          <div>
            <template v-for="comment in comments" :key="comment.id">
              <div class="w-full text-left border-b mb-2">
                <span class="text-xs font-bold text-primary">{{
                  comment.created_at
                }}</span
                ><br />
                <span class="text-lg">{{ comment.message }}</span>
              </div>
            </template>
          </div>
        </div>
      </div>
      <input type="file" id="fileupload" @change="prepareUploadFile()" hidden />
    </div>
  </div>
</template>

<script>
import API from "@/API/Private";
import Encryption from "@/Encryption";
import { useCookies } from "vue3-cookies";
import AddFileModal from "../AddFileModal.vue";

export default {
  components: { AddFileModal },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  expose: ["loadComments"],
  props: {
    application: Object,
    cryptKey: String,
  },
  emits: [
    "closeDetails",
    "fileReceived",
    "applicationUpdated",
    "sentCancelMail",
    "deleteOrArchiveApplication",
    "deleteApplication",
  ],
  data() {
    return {
      editableApplication: {},
      editName: false,
      comments: [],
      newComment: "",
      addCommentVisible: false,
      cancelToolTip: "",
      displayInterviewPopup: false,
      files: {},
      showAddFileModal: false,
    };
  },
  methods: {
    getFile(file) {
      let vm = this;
      API.getFile(file.id, (receivedFile) => {
        console.log(receivedFile)
        if (receivedFile) {
          vm.$emit("fileReceived", receivedFile);
        }
      });
    },
    loadComments() {
      let vm = this;
      API.getCommentsForApplication(this.editableApplication, (comments) => {
        vm.comments = comments;
        vm.newComment = "";
        vm.editableApplication.comments = comments.length;
      });
    },

    deleteFile(file) {
      let vm = this;
      API.deleteFile(file.id, (s) => {
        vm.$emit("applicationUpdated");
      });
    },
    addFile(file) {
      this.showAddFileModal = false;
      this.editableApplication.files.push(file);
      this.uploadApplicationFile(file);
      //document.getElementById("fileupload").click();
    },
    uploadApplicationFile(file) {
      let vm = this;
      console.log(this.editableApplication.id);
      API.uploadApplicationFile(this.editableApplication.id, file, (s) => {
        if (s) {
          console.log(s);
          vm.$emit("applicationUpdated");
          file = s;
        }
      });
    },
    prepareUploadFile() {
      let vm = this;
      let fr = new FileReader();
      document.getElementById("fileupload").files.forEach((file) => {
        fr.readAsArrayBuffer(file);
        fr.onload = function () {
          var data = fr.result;
          vm.editableApplication.files.push({
            data: Encryption.arrayBufferToBase64(data),
            originalName: file.name,
            documentType: "",
          });
        };
      });
      document.getElementById("fileupload").value = null;
    },
    showAddComment() {
      this.addCommentVisible = true;
      console.log(this.$refs);
      setTimeout(() => {
        this.$refs.comment.focus();
      }, 100);
    },
    addComment() {
      let vm = this;
      let comment = {
        message: this.newComment,
        application: this.application.id,
      };
      API.addComment(comment, (s) => {
        if (s) {
          vm.loadComments();
          vm.addCommentVisible = false;
        }
      });
    },
    preprocessApplicant(applicant = false) {
      if (applicant) {
        this.editableApplication = applicant;
      } else {
        this.editableApplication = this.application;
      }
      if (this.editableApplication.scheduled)
        this.editableApplication.scheduled =
          this.editableApplication.scheduled.replace(":00Z", "");
      this.loadComments();
    },
    updateApplication(field) {
      let update = {};
      let vm = this;
      update[field] = this.editableApplication[field];

      API.updateApplication(this.editableApplication.id, update, (success) => {
        if (success) {
          if (field == "status") {
            vm.$emit("applicationUpdated", "status");
          } else {
            vm.$emit("applicationUpdated");
          }
        }
      });
    },
  },
  created: function () {
    this.preprocessApplicant();
    let vm = this;
    API.getFiles((files) => {
      vm.files = files;
    });
  },
  watch: {
    application(newApplication, oldApplication) {
      this.preprocessApplicant(newApplication);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.detailscontent {
  width: 100%;
  height: 100%;
  border-left: 4px solid #a3bbc7;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
}

option,
option:checked {
  background: #292f4c;
}

input,
select {
  padding: unset;
  border: unset;
  background: unset;
  margin: unset;
}

select {
  width: unset;
}
</style>