<template>
  <div
    ref="modalbg"
    class="fixed z-10 center w-full h-full sm:pb-10 bg-black/50 text-center sm:pl-56 pt-20"
    @click="checkClosing($event)"
  >
    <AddFileModal
      v-if="showAddFileModal"
      kind="Dokument"
      @closeModal="showAddFileModal = false"
      @addFile="addFile"
    />
    <div
      ref="modalcontent"
      class="z-20 w-full sm:w-11/12 bg-white sm:rounded-3xl py-8 inline-block text-left sm:mt-5 relative h-full overflow-y-hidden"
    >
      <div class="w-full h-full inline-block">
        <div
          @click="$emit('closeApplicationDetailsPopup')"
          class="iconborder-modal z-30"
        >
          <font-awesome-icon size="lg" icon="xmark" class="inborder" />
        </div>
        <div class="w-full h-full rounded overflow-y-auto pt-10">
          <div class="absolute bg-white py-5 top-0 right-0 w-full text-center">
            <div v-if="currentApplication.name" class="inline-block">
              <span class="font-bold text-3xl">{{
                currentApplication.name
              }}</span
              ><br />
              <span
                >Direktlink:
                <a :href="feDomain + '/b/board?a=' + currentApplication.id"
                  >{{ feDomain }}/b/board?a={{ currentApplication.id }}</a
                ></span
              >
            </div>
            <span v-else class="font-bold text-3xl">Bewerber anlegen</span>
          </div>
          <div class="w-full grid grid-cols-3">
            <div
              class="px-4 border-black border-r col-span-3"
              :class="{ 'xl:col-span-1': currentFile }"
            >
              <div
                class="w-full text-left mt-6 mb-2 md:grid md:grid-cols-2"
                :class="{ 'xl:inline-block': currentFile }"
                v-if="application.status != 'Archiviert'"
              >
                <span class="text-xl font-bold">Steuerung</span><br />
                <div class="pr-2 mt-2">
                  <span class="text-lg">Stelle</span>
                  <div
                    class="rounded-full float-right bg-secondary text-primary font-semibold py-1 px-2"
                  >
                    <select
                      class="bg-secondary font-bold p-0 mt-0 w-36"
                      v-model="application.job"
                    >
                      <option
                        v-for="job in filteredJobs"
                        :key="job"
                        :value="job.id"
                      >
                        {{ job.title }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="pr-2 mt-2">
                  <span class="text-lg">Status</span>
                  <div
                    class="rounded-full float-right bg-secondary text-primary font-semibold py-1 px-2"
                  >
                    <select
                      class="bg-secondary font-bold p-0 mt-0 w-36"
                      v-model="application.status"
                    >
                      <option value="Abgesagt">Abgesagt</option>
                      <option value="Eingegangen">Eingegangen</option>
                      <option value="Interview">Interview</option>
                      <option value="Angebot">Angebot</option>
                    </select>
                  </div>
                </div>
                <div v-if="application.status == 'Eingegangen'" class="w-full">
                  <div class="w-full text-left flex flex-row">
                    <span class="grow text">HR-geprüft:</span>
                    <div class="align-middle text-right w-36">
                      <input
                        id="approved"
                        v-model="application.approved"
                        type="checkbox"
                      /><label for="approved" class="box-right"></label>
                    </div>
                  </div>
                </div>
                <div class="pr-2 mt-2">
                  <span class="text-lg">Zuständig:</span>
                  <div
                    class="rounded-full float-right bg-secondary text-primary font-semibold py-1 px-2"
                  >
                    <select
                      class="bg-secondary font-bold p-0 mt-0 mr-4"
                      v-model="application.currentTask"
                    >
                      <option value="">Niemand</option>
                      <option
                        v-for="member in teammembers"
                        :key="member"
                        :value="member"
                      >
                        {{ member.split("@")[0] }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="pl-4 w-full">
                <div class="border-b border-b-black w-full my-2"></div>
              </div>
              <div class="w-full mt-2 flex flex-row" v-if="application.id">
                <div class="inline-block grow text-center">
                  <button @click="sendMail('Cancel')" class="bad mx-2">
                    Absagen
                  </button>

                  <button @click="$emit('sentCancelMail')" class="bad mx-2">
                    Löschen</button
                  ><button
                    v-if="application.status == 'Angebot'"
                    @click="employed()"
                    class="positive mx-2 mt-2"
                  >
                    Eingestellt
                  </button>
                </div>
              </div>
              <div
                v-if="application.status == 'Interview'"
                class="text-center relative"
              >
                <div class="w-full text-left">
                  <span class="text-left text-xl">Interviews</span>
                  <div
                    class="iconborder"
                    @click="interviews++"
                    v-if="interviews != 3"
                  >
                    <font-awesome-icon icon="plus" class="inborder" />
                  </div>
                  <div class="grid grid-cols-2">
                    <div class="pr-2">
                      <div class="w-full text-left mt-2 py-1 flex flex-row">
                        <span class="font-bold">Termin 1: </span>
                        <div class="lign-middle text-right grow">
                          <input
                            class="w-48 inline-block font-semibold text-left"
                            type="datetime-local"
                            v-model="application.scheduled"
                          />
                        </div>
                      </div>
                      <div class="w-full text-left py-1 flex flex-row">
                        <span class="grow text">Bestätigt:</span>
                        <div class="align-middle text-left flex w-36">
                          <input
                            id="confirmed"
                            v-model="application.confirmed"
                            class="w-0"
                            type="checkbox"
                          /><label for="confirmed" class="box-right"></label>
                        </div>
                        <div></div>
                      </div>
                      <div class="w-full text-center">
                        <button
                          @click="planInterview('')"
                          class="positive mx-2 mt-2"
                        >
                          Intervieweinladung
                        </button>
                      </div>
                    </div>
                    <div v-if="interviews >= 2">
                      <div class="w-full text-left mt-2 py-1 flex flex-row">
                        <span class="font-bold text">Termin 2: </span>
                        <div class="lign-middle text-right grow">
                          <input
                            class="w-48 inline-block font-semibold text-left"
                            type="datetime-local"
                            v-model="application.scheduled2"
                          />
                        </div>
                      </div>
                      <div class="w-full text-left py-1 flex flex-row">
                        <span class="grow text">Bestätigt:</span>
                        <div class="align-middle text-left flex w-36">
                          <input
                            id="confirmed2"
                            v-model="application.confirmed2"
                            class="w-0"
                            type="checkbox"
                          /><label for="confirmed2" class="box-right"></label>
                        </div>
                      </div>
                      <div class="w-full text-center">
                        <button
                          @click="planInterview('2')"
                          class="positive mx-2 mt-2"
                        >
                          Intervieweinladung
                        </button>
                      </div>
                    </div>
                    <div v-if="interviews >= 3">
                      <div class="w-full text-left mt-2 py-1 flex flex-row">
                        <span class="font-bold text">Termin 3: </span>
                        <div class="align-middle text-right grow">
                          <input
                            class="w-48 inline-block font-semibold text-left"
                            type="datetime-local"
                            v-model="application.scheduled3"
                          />
                        </div>
                      </div>
                      <div class="w-full text-left py-1 flex flex-row">
                        <span class="grow text">Bestätigt:</span>
                        <div class="align-middle text-left flex w-36">
                          <input
                            id="confirmed3"
                            v-model="application.confirmed3"
                            class="w-0"
                            type="checkbox"
                          /><label for="confirmed3" class="box-right"></label>
                        </div>
                      </div>
                      <div class="w-full text-center">
                        <button
                          @click="planInterview('3')"
                          class="positive mx-2 mt-2"
                        >
                          Intervieweinladung
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="pl-4">
                    <div class="border-b border-b-black w-full my-2"></div>
                  </div>
                </div>
              </div>
              <span class="font-bold text-xl">Persönliche Daten</span>
              <application-details ref="details" :application="application" />
              <span class="font-semibold text-xl !mt-3 inline-block"
                >Dokumente</span
              >
              <div class="iconborder" @click="showAddFileModal = true">
                <font-awesome-icon icon="plus" class="inborder" />
              </div>
              <div class="relative">
                <div v-if="loadingFile" class="absolute bg-white w-full h-full">
                  <div class="lds-dual-ring"></div>
                </div>
                <div class="text-left">
                  <span class="font-bold block">Anschreiben:</span>
                  <span
                    v-if="
                      application.files.filter((f) => f.type == 'Anschreiben')
                        .length == 0
                    "
                    class="block"
                  >
                    -
                  </span>
                  <div v-else class="relative">
                    <div
                      v-for="file in application.files.filter(
                        (f) => f.type == 'Anschreiben'
                      )"
                      :key="file.id"
                      class="mt-2"
                    >
                      <div class="w-full flex flex-row">
                        <div
                          class="border-b-2 border-secondary cursor-pointer"
                          @click="loadFile(file)"
                        >
                          <span v-if="file.name">{{ file.name }}</span
                          ><span v-else>{{ file.originalName }}</span>
                        </div>
                        <div
                          class="flex content-center justify-end grow flex-wrap"
                        >
                          <font-awesome-icon
                            icon="trash"
                            @click="deleteFile(file)"
                            class="pr-1 px-2 text-secondary cursor-pointer"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <span class="font-bold block mt-2">Lebenslauf:</span>
                  <span
                    v-if="
                      application.files.filter((f) => f.type == 'Lebenslauf')
                        .length == 0
                    "
                    class="block"
                  >
                    -
                  </span>
                  <div v-else class="relative">
                    <div
                      v-for="file in application.files.filter(
                        (f) => f.type == 'Lebenslauf'
                      )"
                      :key="file.id"
                      class="mt-2"
                    >
                      <div class="w-full flex flex-row">
                        <div
                          class="border-b-2 border-secondary cursor-pointer"
                          @click="loadFile(file)"
                        >
                          <span v-if="file.name">{{ file.name }}</span
                          ><span v-else>{{ file.originalName }}</span>
                        </div>
                        <div
                          class="flex content-center justify-end grow flex-wrap"
                        >
                          <font-awesome-icon
                            icon="trash"
                            @click="deleteFile(file)"
                            class="pr-1 px-2 text-secondary cursor-pointer"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <span class="font-bold block mt-2">Qualifikationen:</span>
                  <span
                    v-if="
                      application.files.filter(
                        (f) => f.type == 'Qualifikationen'
                      ).length == 0
                    "
                    class="block"
                  >
                    -
                  </span>
                  <div v-else class="relative">
                    <div
                      v-for="file in application.files.filter(
                        (f) => f.type == 'Qualifikationen'
                      )"
                      :key="file.id"
                      class="mt-2"
                    >
                      <div class="w-full flex flex-row">
                        <div
                          class="border-b-2 border-secondary cursor-pointer"
                          @click="loadFile(file)"
                        >
                          <span v-if="file.name">{{ file.name }}</span
                          ><span v-else>{{ file.originalName }}</span>
                        </div>
                        <div
                          class="flex content-center justify-end grow flex-wrap"
                        >
                          <font-awesome-icon
                            icon="trash"
                            @click="deleteFile(file)"
                            class="pr-1 px-2 text-secondary cursor-pointer"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <span class="font-bold block mt-2">Sonstiges:</span>
                  <span
                    v-if="
                      application.files.filter((f) =>
                        ['', 'Sonstiges'].includes(f.type)
                      ).length == 0
                    "
                    class="block"
                  >
                    -
                  </span>
                  <div v-else class="relative">
                    <div
                      v-for="file in application.files.filter((f) =>
                        ['', 'Sonstiges'].includes(f.type)
                      )"
                      :key="file.id"
                      class="mt-2"
                    >
                      <div class="w-full flex flex-row">
                        <div
                          class="border-b-2 border-secondary cursor-pointer"
                          @click="loadFile(file)"
                        >
                          <span v-if="file.name">{{ file.name }}</span
                          ><span v-else>{{ file.originalName }}</span>
                        </div>
                        <div
                          class="flex content-center justify-end grow flex-wrap"
                        >
                          <font-awesome-icon
                            icon="trash"
                            @click="deleteFile(file)"
                            class="pr-1 px-2 text-secondary cursor-pointer"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5">
                <span class="font-semibold text-xl">Kommentare</span>
                <div
                  class="w-full text-left border-b mb-2"
                  v-for="comment in application.comments"
                  :key="comment.id"
                >
                  <div>
                    <span class="font-bold text-primary">{{
                      comment.author
                    }}</span
                    ><font-awesome-icon
                      icon="thumbtack"
                      class="float-right mt-1 cursor-pointer"
                      :class="{ 'text-primary': comment.pinned }"
                      @click="pinComment(comment)"
                    /><span class="float-right mr-2 text-primary font-bold text-xs">{{
                      comment.time
                    }}</span>
                  </div>
                  <span class="break-words w-full overflow-y-auto">{{
                    comment.message
                  }}</span>
                </div>
                <textarea
                  v-model="application.comment"
                  class="w-full mt-2 h-24 overflow-y-auto bg-gray-100 p-2 resize-none"
                  placeholder="Schreib einen Kommentar..."
                ></textarea>
              </div>
            </div>
            <div
              v-if="currentFile"
              class="px-4 pt-2 overflow-x-auto col-span-2 hidden xl:block"
            >
              <FileViewer
                v-if="currentFile"
                :file="currentFile"
                :cryptKey="cryptKey"
                @fileUpdated="fileUpdated"
              />
            </div>
          </div>
          <div class="text-center w-full mt-7">
            <button @click="saveApplication(false,false,false)" class="relative m-2">
              <div v-if="loading" class="lds-dual-ring"></div>
              <span v-bind:class="[loading ? 'text-white' : '']"
                >Speichern</span
              >
            </button>
            <button @click="saveApplication()" class="relative m-2">
              <div v-if="loading" class="lds-dual-ring"></div>
              <span v-bind:class="[loading ? 'text-white' : '']"
                >Speichern & schließen</span
              >
            </button>
            <button @click="saveApplication(true)" class="relative m-2">
              <div v-if="loading" class="lds-dual-ring"></div>
              <span v-bind:class="[loading ? 'text-white' : '']"
                >Speichern + Eingang bestätigen</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/API/Business";
import { useCookies } from "vue3-cookies";
import ApplicationDetails from "./ApplicationDetails.vue";
import AddFileModal from "./AddFileModal.vue";
import FileViewer from "./FileViewer.vue";
export default {
  components: { ApplicationDetails, AddFileModal, FileViewer },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  props: {
    currentJob: Object,
    cryptKey: String,
    currentApplication: Object,
    jobs: Array,
  },
  emits: [
    "applicationSaved",
    "applicationUpdated",
    "closeApplicationDetailsPopup",
    "sentCancelMail",
  ],
  data() {
    return {
      feDomain: process.env.VUE_APP_FE_DOMAIN,
      filteredJobs: [],
      teammembers: [],
      loading: false,
      loadingFile: false,
      interviews: 1,
      application: {
        job: this.currentJob.id,
        encrypted: true,
        details: {},
        files: [],
        comment: "",
        status: "Eingegangen",
      },
      currentFile: false,
      showAddFileModal: false,
    };
  },
  methods: {
    pinComment(comment) {
      console.log("hello");
      API.pinComment(comment, (s) => {});
      comment.pinned = !comment.pinned;
      if (comment.pinned) {
        this.editableApplication.pinnedComments.push(comment);
      } else {
        this.editableApplication.pinnedComments.splice(
          this.editableApplication.pinnedComments.indexOf(comment),
          1
        );
      }
    },
    fileUpdated(file) {
      let oldFile = this.application.files.filter(
        (f) => f.id == this.currentFile.id
      )[0];
      let index = this.application.files.indexOf(oldFile);
      console.log(oldFile);
      console.log(index);
      if (index > -1) {
        this.application.files[index] = file;
      }
    },
    loadFile(file) {
      this.loadingFile = true;
      API.getFile(file.id, this.cryptKey, (file) => {
        this.currentFile = file;
        this.loadingFile = false;
      });
    },
    planInterview(number) {
      this.interviewDate = this.application["scheduled" + number];
      console.log(this.interviewDate);
      this.sendMail("Interview");
    },
    employed() {
      this.application.archived = true;
      this.application.employed = true;
      this.saveApplication(false, "employed");
    },
    sendMail(type) {
      let vm = this;
      if (this.application.status == "Abgesagt") {
        if (type == "Cancel") {
          vm.$emit("sentCancelMail");
        }
        return;
      }
      API.getTemplates((templates) => {
        let text = templates["template" + type];
        let es = Date.parse(this.interviewDate);
        if (!isNaN(es)) {
          let d = new Date(es).toLocaleString("de-DE");
          text = text.replace("{Interviewdatum}", d.split(",")[0]);
          text = text.replace(
            "{Interviewuhrzeit}",
            d.split(",")[1].substr(0, d.split(",")[1].length - 3)
          );
        }
        text = text.replace("{Name}", vm.application.name);
        console.log(vm.application.job);
        text = text.replace("{Jobtitel}", vm.application.jobdata.title);
        //text = text.replaceAll("\n", "%0D%0A");
        text = encodeURIComponent(text);
        console.log(
          "mailto:" +
            vm.application.mail +
            "?subject=" +
            templates["subject" + type] +
            "&body=" +
            text,
          "_blank"
        );
        window.open(
          "mailto:" +
            vm.application.mail +
            "?subject=" +
            templates["subject" + type] +
            "&body=" +
            text,
          "_blank"
        );
        if (type == "Cancel") {
          vm.$emit("sentCancelMail");
        }
      });
    },

    saveApplication(confirmMail = false, showUpdates = false, close=true) {
      this.loading = true;
      let vm = this;
      if (this.$refs["details"].validateFields()) {
        this.application = this.$refs["details"].setDetails(
          this.application,
          false
        );
      } else {
        this.loading = false;
        return;
      }
      API.addApplication(this.application, vm.cryptKey, (application) => {
        if (application) {
          this.loading = false;
          if (showUpdates) {
            vm.$emit("applicationUpdated", showUpdates, close);
          } else {
            vm.$emit("applicationSaved", close);
          }
          if (confirmMail) {
            vm.sendReceivedMail();
          }
        }
      });
    },
    checkClosing(event) {
      console.log(event);
      if (event.target == this.$refs.modalbg) {
        this.$emit("closeApplicationDetailsPopup");
      }
    },
    addFile(file) {
      this.showAddFileModal = false;
      this.application.files.push(file);
      this.currentFile = file;
    },
    deleteFile(file) {
      this.application.files.splice(this.application.files.indexOf(file), 1);
    },
    taskDone() {
      console.log("TASKDONE");
      if (this.waitFor <= 1) {
        this.$emit("applicationSaved");
      } else {
        this.waitFor--;
      }
    },
    sendReceivedMail() {
      let vm = this;
      API.getTemplates((templates) => {
        let text = templates.templateReceived;
        text = text.replaceAll("{Name}", vm.application.name);
        text = text.replaceAll("{Jobtitel}", vm.currentJob.Titel);
        console.log(text);
        window.open(
          "mailto:" +
            vm.application.mail +
            "?subject=" +
            templates.subjectReceived +
            "&body=" +
            encodeURIComponent(text),
          "_blank"
        );
      });
    },
    getTeammembers() {
      let jobId = this.currentApplication
        ? this.currentApplication.job
        : this.currentJob.id;
      let vm = this;
      API.getTeammembers(jobId, (teammembers) => {
        vm.teammembers = teammembers;
      });
    },
  },
  created: function () {
    this.filteredJobs = this.jobs.filter(
      (job) => !["Postfach-Integration", "Alle Stellen"].includes(job.title)
    );
    this.getTeammembers();
    console.log(this.jobs);
    if (this.currentApplication && this.currentApplication.id) {
      this.application = JSON.parse(JSON.stringify(this.currentApplication));

      if (this.application.scheduled2) this.interviews++;
      if (this.application.scheduled3) this.interviews++;
      console.log(this.application);
      API.getCommentsForApplication(
        this.application,
        this.cryptKey,
        (comments) => {
          this.application.comments = comments;
          this.application.comment = "";
        }
      );
      if (this.application.files.length > 0) {
        let fileId = this.application.files[0].id;
        if (
          this.application.files.filter((file) => file.type == "Lebenslauf")
            .length > 0
        ) {
          fileId = this.application.files.filter(
            (file) => file.type == "Lebenslauf"
          )[0].id;
        }
        API.getFile(fileId, this.cryptKey, (receivedFile) => {
          if (receivedFile) {
            this.currentFile = receivedFile;
          }
        });
      }
    }
  },
};
</script>

<style scoped>
span {
  margin-top: unset;
}
</style>
