<template>
  <div ref="modalbg" class="fixed sm:pl-56 lg:pt-24 pt-20 z-10 center w-full h-full bg-black/50 text-center lg:pb-4">
    <div v-if="showDelete" ref="modalcontent" class="z-20 lg:w-1/2 bg-white lg:rounded-3xl p-6 pb-10 inline-block text-left relative overflow-y-hidden break-all">
      <div @click="showDelete = !showDelete" class="iconborder">
        <font-awesome-icon icon="xmark" class="inborder" />
      </div>
      <span class="text-2xl font-bold">Stelle archivieren</span>
      <div class="text-left">
        <span><strong>ACHTUNG:</strong> Wenn dieser Job archiviert wird, verlieren
          alle Mitarbeitenden Zugriff und es können keine Bewerbungen mehr verwaltet werden.</span><br /><br /><span>
          Bitte wählen Sie aus, wie mit den Bewerbenden umgegangen werden
          soll:</span>
      </div>
      <div class="text-left mt-3 max-h-96 overflow-y-auto">
        <div v-for="application in allApplications" :key="application" class="py-3 flex">
          <span class="font-bold">{{ application.name }}</span>
          <div v-if="application.status != 'Abgesagt'" class="inline-block flex-grow text-right">
            <button @click="cancel(application)">Absagen</button>
            <button class="ml-2" @click="application.status = 'Abgesagt'">
              Nicht Absagen
            </button>
            <button v-if="application.status == 'Angebot'" class="ml-2" @click="application.handling='employ'" v-bind:class="[
    application.handling == 'employ'
      ? 'border-green-500 text-green-500'
      : '',
  ]">
              Eingestellt
            </button>
          </div>
          <div v-else class="inline-block flex-grow text-right">
            <button class="ml-2" @click="application.handling = 'archive'" v-bind:class="[
    application.handling == 'archive'
      ? 'border-primary text-primary'
      : '',
  ]">
              Archivieren
            </button>
            <button class="ml-2" @click="application.handling = 'talentpool'" v-bind:class="[
    application.handling == 'talentpool'
      ? 'border-primary text-primary'
      : '',
  ]">
              Talentpool
            </button>
          </div>
        </div>
        
      </div>
      <div class="text-center mt-5">
          <button @click="reallyDelete()" v-bind:class="[
    allApplications.filter((el) => el.handling == undefined).length ==
      0
      ? 'border-red-500 text-red-500'
      : 'border-gray-300 text-gray-400 cursor-default',
  ]">
            Wirklich archivieren
          </button>
        </div>
    </div>
    <div v-if="!showDelete" ref="modalcontent" id="modalcontent" class="z-20 lg:w-[800px] bg-white lg:rounded-3xl p-6 pb-10 inline-block text-left relative h-full overflow-y-hidden break-all">
      <div class="w-full h-full inline-block">
        <div @click="$emit('closeJobDetails')" class="iconborder-modal z-30">
          <font-awesome-icon size="lg" icon="xmark" class="inborder" />
        </div>
        <div class="w-full text-center">
          <span class="font-bold text-3xl">{{ headline }}</span>
        </div>
        <div class="w-full h-full overflow-y-auto p-4">
          <div class="pt-8">
            <span class="font-semibold text-xl">Stellenbezeichnung</span>
            <input class="w-full bg-gray-100 p-3 mt-2" placeholder="Stellenbezeichnung" v-model="editableJob.title" />
          </div>
          <div class="grid grid-cols-2">
            <div class="pt-6">
              <span class="block font-semibold text-xl">Eintrittsdatum</span>
              <div v-if="editableJob.targetDate" class="inline-block">
                <input type="date" class="w-full bg-gray-100 p-3 mt-2" v-model="editableJob.targetDate" />
              </div>
              <div class="inline-block ml-3">
                <input id="targetDate" type="checkbox" checked class="w-5/6 bg-gray-100 p-3 mt-2" @change="
    editableJob.targetDate = editableJob.targetDate
      ? ''
      : new Date().toISOString().split('T')[0]
    " /><label for="targetDate">Sofort</label>
              </div>
            </div>

            <div class="pt-6">
              <span class="block font-semibold text-xl">Gehaltsgrenze</span>
              <input class="w-24 bg-gray-100 p-3 mt-2 text-center" type="number" placeholder="40000" v-model="editableJob.salaryTo" />
              € max.
            </div>
            <div class="pt-6">
              <span class="block font-semibold text-xl">Wochenstunden</span>
              <input class="w-24 bg-gray-100 p-3 mt-2 text-center" type="number" placeholder="40" v-model="editableJob.weekhours" />
              h/Woche
            </div>
            <div class="pt-6">
              <span class="block font-semibold text-xl">Standort</span>
              <input class="w-5/6 bg-gray-100 p-3 mt-2" placeholder="Köln / Remote" v-model="editableJob.location" />
            </div>
            <div class="pt-6">
              <span class="block font-semibold text-xl">Abteilung</span>
              <input class="w-5/6 bg-gray-100 p-3 mt-2" placeholder="IT / HR / Zentrale Aufgaben" v-model="editableJob.department" />
            </div>
            <div class="pt-6">
              <input id="homeoffice" v-model="editableJob.homeoffice" type="checkbox" /><label for="homeoffice" class="box-right mt-4 inline-block">Homeoffice möglich</label>
            </div>
            <div class="pt-6">
              <span class="block font-semibold text-xl">Link zur Stellenbeschreibung</span>
              <select class="font-bold mt-2" v-model="editableJob.linktype">
                <option value="applyhr">Applyhr-Link generieren</option>
                <option value="external">Externes Jobportal</option>
              </select>
            </div>
          </div>
          <input v-if="editableJob.linktype == 'external'" class="w-full bg-gray-100 p-3 mt-2" placeholder="https://stepstone.de/job/1241236" v-model="editableJob.link" />


          <div class="pt-6" v-if="user.currentTenantData.hr">
            <span class="font-semibold text-xl">Zusätzliche Zugriffsrechte</span>
            <div class="grid grid-cols-5 text-left py-1 font-semibold">
              <span class="col-span-3">E-Mail</span><span>Rollen</span>

              <div class="inline-block">
                <div class="iconborder-p" @click="addPermission()">
                  <font-awesome-icon icon="plus" class="inborder" />
                </div>
              </div>
            </div>
            <div class="max-h-32 w-full overflow-y-auto">
              <div v-for="permission in editableJob.permissions" :key="permission">
                <div class="grid grid-cols-5 text-left py-1" >
                  <div class="inline-block col-span-3">
                    <input class="font-bold text-secondary w-full" placeholder="E-Mail" v-model="permission.user" />
                  </div>
                  <div class="inline-block col-span-2">
                    <span v-bind:class="[
    permission.hr ? 'roleselected' : 'roledeselected',
  ]" @click="changePermission(permission, 'hr')">HR</span>
                    <span v-bind:class="[
    permission.readonly ? 'roleselected' : 'roledeselected',
  ]" @click="changePermission(permission, 'readonly')">Fachabteilung</span>
                    <font-awesome-icon v-if="permission.user != user.mail" icon="trash" @click="removePermission(permission)" class="pt-1 pr-1 px-2 text-secondary float-right cursor-pointer" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pt-6 break-normal">
            <span class="font-semibold text-xl">Veröffentlichen</span>
            <br />
            <span>Bei Auswahl der "Veröffentlichen"-Option wird ein öffentlicher
              Link generiert, über den Bewerber sich direkt über Applyhr
              bewerben können. Außerdem wird ein QR-Code und ein Flyer
              generiert, mit dem die Stelle in anderen Medien beworben werden
              kann. Welche Daten abgefragt werde kannst du in den </span><a href="/b/org?fields" class="font-bold underline">Einstellungen</a>
            <span>&nbsp;oder weiter unten festlegen.</span> <br /><input id="public" v-model="editableJob.public" type="checkbox" /><label for="public" class="box-right mt-4 inline-block">Veröffentlichen</label>
            <div v-if="editableJob.public">
              <span class="font-bold text-lg mt-4 inline-block">Links</span><br />
              <span class="font-bold">Link zur Stelle:</span><span class="inline-block ml-3 bg-gray-100">{{ publicLink }}</span><br>

              <iframe id="printf" name="printf" src="/b/flyer?j=1" class="opacity-0 absolute" />
              <a v-if="publicLink" class="underline font-bold cursor-pointer" @click="download()">Flyerdownload (Beta)</a><br>
              <a v-if="publicLink" id="qrlink" :download="qrname" target="_blank" :href="qrdataurl" class="underline font-bold cursor-pointer">QR-Download</a>

              <span v-else>Links werden beim erstmaligen Speichern generiert und können
                dann hier eingesehen werden.</span><br />
              <span class="font-bold text-lg mt-4 inline-block">Aufgaben:</span><br />
              <textarea v-bind:class="[!editableJob.tasks || editableJob.tasks.length < 5 ? 'h-10 overflow-hidden' : 'min-h-xxs',]" v-model="editableJob.tasks" placeholder="Inhalt eintragen, damit dieser beim Stellenprofil angezeigt wird."
                class="p-2 w-full bg-gray-100 "></textarea><br />
              <span class="font-bold text-lg mt-4 inline-block">Profil:</span><br />
              <textarea v-bind:class="[!editableJob.profile || editableJob.profile.length < 5 ? 'h-10 overflow-hidden' : 'min-h-xxs',]" v-model="editableJob.profile"
                placeholder="Inhalt eintragen, damit dieser beim Stellenprofil angezeigt wird." class="p-2 w-full bg-gray-100"></textarea><br />
              <span class="font-bold text-lg mt-4 inline-block">{{ user.currentTenantData.name }} bietet:</span><br />
              <textarea v-bind:class="[!editableJob.benefits || editableJob.benefits.length < 5 ? 'h-10 overflow-hidden' : 'min-h-xxs',]" v-model="editableJob.benefits"
                placeholder="Inhalt eintragen, damit dieser beim Stellenprofil angezeigt wird." class="p-2 w-full bg-gray-100"></textarea><br />
              <div class="mt-3 w-full">
                <span class="text-lg font-bold">Allgemeine Pflichtangaben</span>
                <div class="grid grid-cols-2">
                  <div>
                    <input id="askPhone" type="checkbox" v-model="editableJob.requiredFields.phone" /><label for="askPhone" class="text-lg">Telefonnummer</label>
                  </div>
                  <div>
                    <input id="askstartDate" type="checkbox" v-model="editableJob.requiredFields.startDate" /><label for="askstartDate" class="text-lg">Startdatum</label>
                  </div>
                  <div>
                    <input id="askweekhours" type="checkbox" v-model="editableJob.requiredFields.weekhours" /><label for="askweekhours" class="text-lg">Wochenstunden</label>
                  </div>
                  <div>
                    <input id="asksalary" type="checkbox" v-model="editableJob.requiredFields.salary" /><label for="asksalary" class="text-lg">Gehaltsvorstellung</label>
                  </div>
                  <div>
                    <input id="askbirthdate" type="checkbox" v-model="editableJob.requiredFields.birthdate" /><label for="askbirthdate" class="text-lg">Geburtsdatum</label>
                  </div>
                  <div>
                    <input id="askcity" type="checkbox" v-model="editableJob.requiredFields.city" /><label for="askcity" class="text-lg">Wohnort</label>
                  </div>
                </div>
                <div class="mt-5">
                  <span class="text-lg font-bold">Erforderliche Dokumente</span>
                  <div class="grid grid-cols-2">
                    <div>
                      <input id="askCover" type="checkbox" v-model="editableJob.requiredFields.cover" /><label for="askCover" class="text-lg">Anschreiben</label>
                    </div>
                    <div>
                      <input id="askCV" type="checkbox" v-model="editableJob.requiredFields.cv" /><label for="askCV" class="text-lg">Lebenslauf</label>
                    </div>
                    <div>
                      <input id="askQualificatios" type="checkbox" v-model="editableJob.requiredFields.qualifications" /><label for="askQualificatios" class="text-lg">Qualifikationen</label>
                    </div>
                  </div>
                </div>
                <div class="mt-5">
                  <span class="text-lg font-bold">Folgende Daten werden immer abgefragt:</span>
                  <div class="grid grid-cols-2">
                    <div>
                      <input id="cover" class="disabled" type="checkbox" checked disabled /><label for="name" class="text-lg">Name</label>
                    </div>
                    <div>
                      <input id="cover" class="disabled" type="checkbox" checked disabled /><label for="name" class="text-lg">E-Mailadresse</label>
                    </div>
                    <div>
                      <input id="misc" class="disabled" type="checkbox" checked disabled /><label for="misc" class="text-lg">Sonstige Dokumente</label>
                    </div>
                  </div>
                </div>
                <div class="mt-5">
                  <span class="inline-block text-lg font-bold">Ansprechpartner für Bewerbungen</span>
                  <div class="w-full pr-3 py-3">
                    <span class="font-semibold ">Vor- und Nachname</span>
                    <input class="w-full bg-gray-100 pr-3 py-3 mt-2" placeholder="Vor- und Nachname" v-model="editableJob.contact_name" />
                  </div>
                  <div class="grid md:grid-cols-2">
                    <div class="pt-6 pr-3 py-3">
                      <span class="block font-semibold ">E-Mail</span>
                      <input class="w-full bg-gray-100 p-3 mt-2" placeholder="E-Mail" v-model="editableJob.contact_mail" />
                    </div>
                    <div class="pt-6 pr-3 py-3">
                      <span class="block font-semibold ">Telefonnummer</span>
                      <input class="w-full bg-gray-100 p-3 mt-2" placeholder="Telefonnummer" v-model="editableJob.contact_phone" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center w-full" v-if="user.currentTenantData.hr || editableJob.ownRole.hr">
            <div v-if="errors" class="">
              <span class="text-red-500 font-bold m-2">Bitte korrigiere die folgenden Angaben</span>
              <div v-for="key in errorkeys" :key="key">
                <li>{{ fields_i18n[key] }}: {{ errors[key][0] }}</li>
              </div>
            </div>
            <div v-if="create" class="pt-3">
              <button @click="saveJob()" class="border-red-500 text-red-500">
                Job kostenpflichtig für 0,20 € / Tag erstellen</button><br />
              <span class="mt-5 inline-block">Mit Klick auf diese Schaltfläche akzeptieren Sie die <br /><a class="underline" target="_blank" href="https://www.applyhr.de/datenschutz/">Datenschutz-</a>
                und
                <a class="underline" target="_blank" href="https://www.applyhr.de/nutzungsbedingungen">Nutzungsbedingungen</a></span>
            </div>

            <div v-else-if="!editableJob.archived" class="pt-3">
              <button @click="deleteJob()" class="border-red-400 text-red-400 mx-5">
                Archivieren
              </button>
              <button @click="saveJob()" class="mx-5">Speichern</button>
            </div>
            <div v-else class="pt-3">
              <button @click="reactivate()" class="mx-5 border-red-400 text-red-400">Kostenpflichtig reaktivieren (0,20€ / Tag) </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-qrious :value="publicLink" @change="onDataUrlChange" :size="600" :padding="20" hidden />
  </div>
</template>

<script>
import API from "@/API/Business";
import { useCookies } from "vue3-cookies";
import VueQrious from "vue-qrious";

export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  components: {
    VueQrious,
  },
  emits: ["jobSaved", "closeJobDetails"],
  expose: ["someMethod"],
  props: {
    job: Object,
    newJob: Boolean,
    user: Object,
    applications: Object,
    cryptKey: String,
  },
  data() {
    return {
      allApplications: [],
      templates: {},
      create: false,
      editableJob: {
        requiredFields: "",
        targetDate: "",
        permissions: [{ user: this.user.mail, hr: true, readonly: false }],
        ownRole: { user: this.user.mail, hr: true, readonly: false },
        linktype: "applyhr",
        benefits: this.user.currentTenantData.benefits,
      },
      headline: "Stelle anlegen",
      showDelete: false,
      qrurl: "",
      errors: null,
      errorkeys: [],
      fields_i18n: {
        title: "Titel",
      },
      publicLink: "",
      qrname: "",
      qrdataurl: "",
    };
  },
  methods: {
    deleteJob() {
      this.allApplications=this.applications.received.concat(this.applications.offer, this.applications.interview)
      this.showDelete = true;
    },
    onDataUrlChange(url) {
      this.qrdataurl = url;
    },
    cancel(application) {
      application.status = "Abgesagt";
      API.getTemplates((templates) => {
        let text = templates.cancel;
        text = text.replace("{Name}", application.name);
        text = text.replace("{Jobtitel}", application.jobtitle);
        window.open(
          "mailto:" +
          application.mail +
          "?subject=Ihre Bewerbung bei uns&body=" +
          text,
          "_blank"
        );
      });
    },
    download() {
      window.frames["printf"].focus();
      setTimeout(() => {
        window.frames["printf"].print();
      }, 1);
    },
    reallyDelete() {
      if (
        this.allApplications.filter((el) => el.handling == undefined).length ==
        0
      ) {
        this.allApplications.forEach((application) => {
          API.updateApplication(
            {
              archived: true,
              status: "Abgesagt",
              talentpool: application.handling == "talentpool",
              employed: application.handling == "employ",
            },
            this.cryptKey,
            application.id,
            () => { }
          );
        });
        this.editableJob.archived = true;
        this.saveJob(true);
      }
    },
    changePermission(permission, role) {
      if (permission.user == this.user.mail) return;
      permission[role] = !permission[role];
      if (role == "hr" && permission[role]) permission.readonly = false;
      if (role == "readonly" && permission[role]) permission.hr = false;
    },
    addPermission() {
      this.editableJob.permissions.push({
        user: "",
        readonly: true,
        hr: false,
      });
    },
    removePermission(permission) {
      this.editableJob.permissions.splice(
        this.editableJob.permissions.indexOf(permission),
        1
      );
    },
    reactivate() {
      this.editableJob.archived = false;
      this.saveJob();
    },
    saveJob(clear = false) {
      let vm = this;
      if (this.editableJob.public && this.editableJob.linktype == "applyhr") {
        this.editableJob.link = this.publicLink;
      }
      if (this.newJob) {
        API.addJob(this.editableJob, (newJob) => {
          console.log(newJob);
          if (newJob.errors) {
            vm.errors = newJob.errors;
            vm.errorkeys = Object.keys(newJob.errors);
          } else if (newJob) {
            vm.$emit("jobSaved", true);
          }
        });
      } else {
        API.updateJob(this.editableJob, (updatedJob) => {
          if (updatedJob) {
            if(updatedJob.archived){
              window.location="/b/board"
            }else{
            vm.$emit("jobSaved", clear);
            }
          }
        });
      }
    },

    checkClosing(event) {
      console.log(event);
      if (event.target == this.$refs.modalbg) {
        this.$emit("closeJobDetails");
      }
    },

  },
  created: function () {
    setTimeout(() => {
      if (document.getElementById("printf"))
        document.getElementById("printf").focus();
    });

    if (!this.newJob) {
      this.editableJob = this.job;
      this.headline = "Stelle bearbeiten";
      this.allApplications = this.applications.received.concat(
        this.applications.offer,
        this.applications.interview
      );
      this.publicLink =
        process.env.VUE_APP_FE_DOMAIN +
        "/p/" +
        this.user.currentTenantData.slug +
        "?j=" +
        this.job.id;
      this.qrname = "QR_" + this.job.title.replaceAll(" ", "_") + ".png";
    } else {
      this.create = true;
      this.editableJob.requiredFields = this.user.currentTenantData.defaults.requiredJobFields

      Object.assign(
        this.editableJob,
        this.user.currentTenantData.defaults.contact
      );
    }
    setTimeout(() => {
      if (!this.user.currentTenantData.hr) {
        let els = Array.from(
          document.getElementById("modalcontent").getElementsByTagName("input")
        ).concat(
          Array.from(
            document
              .getElementById("modalcontent")
              .getElementsByTagName("select")
          )
        );
        for (let i = 0; i < els.length; i++) {
          console.log(els[i]);
          els[i].disabled = true;
        }
      }
    }, 500);

    console.log(this.editableJob);
  },
};
</script>

<style scoped></style>